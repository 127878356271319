@import 'partials/_variables';

// @import '../../node_modules/normalize.css/normalize.css';
@import '../../node_modules/magnific-popup/src/css/main';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/brands';
@import url('https://fonts.googleapis.com/css?family=Cinzel:400,700,900');

* {
  box-sizing: border-box;
}

body {
  font-family: 'Cinzel', sans-serif;
  font-weight: 400;
  background-color: $background;
  font-size: 16px;
}

.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("../img/background.jpg") no-repeat center right fixed;
  background-size: cover;

  @media (min-width: 768px) and (max-width: 1024px) {
    background-position: 85% center;
  }

  &:before {
    // @media (max-width: 480px) {
    //   content: '';
    // }
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $background;
    opacity: 0.3;
  }
}

#logo {
  width: 80%;
  @media (min-width: 768px) {
    width: 70%;
  }

  @media (min-width: 992px) {
    width: 50%;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }

  @media (min-width: 1500px) {
    width: 40%;
  }
}

.content {
  padding-top: 40px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  width: 100%;
  font-size: 14px;
  line-height: 15px;

  @media (min-width: 768px) {
    line-height: 18px;
    font-size: 16px;
    width: 80%;
    bottom: auto;
    padding-top: 50px;
  }

  @media (min-width: 992px) {
    padding-top: 150px;
    width: 50%;
  }

  @media (min-width: 1024px) {
    width: 70%;
  }

  @media (max-height: 900px) and (max-width: 1500px) {
    padding-top: 25px;
  }

  @media (min-width: 2000px) {
    padding-top: 50px;
    width: 50%;
  }

  @media (max-width: 400px) {
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }

  h1 {
    margin: 0;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 1.2;
    font-weight: 700;

    span {
      font-weight: 400;
      font-size: 60%;
      @media (min-width: 768px) {
        font-size: 50%;
      }

      @media (min-width: 1200px) {
        font-size: 70%;
      }

      @media (max-width: 480px) {
        font-size: 65%;
        width: 75%;
        display: inline-block;
      }
    }

    @media (max-width: 480px) {
      font-size: 20px;
      margin-top: 15px;
    }

    .divider {
      display: none;
      position: relative;
      // top: -2px;
      font-weight: 400;
      font-size: 120%;
      @media (min-width: 480px) {
        display: inline;
      }
    }

    .accent {
      color: $accent-color;
    }
  }

  p {
    font-weight: 400;
    padding: 0;

    a {
      text-decoration: none;
    }

    @media (max-width: 320px) {
      display: none;
    }

    // @media (max-width: 400px) {
    //   font-size: 13px;
    // }

    @media (min-width: 768px) {
      padding: 0 30px;
    }
  }
}

.btn {
  display: inline-block;
  margin-top: 20px;
  color: white;
  background-color: $accent-color;
  text-decoration: none;
  text-transform: uppercase;
  padding: 15px 25px;
  border-radius: 6px;
  font-size: 18px;
  transition: background-color 200ms ease-in-out;

  @media (max-width: 320px) {
    position: static;
    bottom: auto;
    left: auto;
    transform: none;
  }

  @media (min-width: 321px) and (max-width: 480px) {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 480px) {
    width: 65%;
  }

  &:focus,
  &:hover {
    background-color: darken($accent-color, 15%);
    transition: background-color 200ms ease-in-out;
    color: white;
  }
}

a {
  color: $accent-color;
  transition: color 200ms ease-in-out;

  &:hover {
    transition: color 200ms ease-in-out;
    color: black;
  }
}

.social {
  position: absolute;
  padding: 10px 0;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 15px;
  background-color: rgba(black, 0.6);

  @media (min-width: 768px) {
    top: 0;
    right: 0;
    bottom: auto;
    padding: 15px;
    margin: 0;
    text-align: right;
    background-color: transparent;
  }

  p {
    text-align: left;
    color: black;
    font-size: 12px;
    text-transform: uppercase;
    padding: 0;
    margin: 0 5px 0 0;
    display: inline;
    vertical-align: top;
    line-height: 40px;

    @media (max-width: 480px) {
      color: white;
      margin: 0 10px 0 0;
    }

    a {
      font-size: inherit;
    }
  }

  > a {
    font-size: 30px;
    display: inline-block;
    margin: 0 5px;

    @media (max-width: 480px) {
      color: white;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
